
  import { defineComponent, ref } from 'vue';
  import axios, { AxiosError } from 'axios';
  import useAlert from "@/composables/Alert"
  import router from '@/router';
  import {
    CampHeader,
    CampFormHeader,
    CampFormRackSubmitBtn,
  } from '@/components';
  import { useLoaderStore } from "@/store/LoaderStore";
  import { campHandleCNPJ } from '@/composables/DataValidation';
  import { Modal } from "bootstrap";
  import  ModalCropImage from './Components/ModalCropImage.vue'
  
  interface IGroup{
    name : string,
    logo? : string,
    responsible : string,
    responsible_telephone : string,
    main_cnpj: string;
  }
  
  function checkEmptyFields(obj): boolean {
    for (let key in obj) {
      if (obj[key] === "" || obj[key] === 0) {
        return true;
      }
    }
    return false;
  }
  
  export default defineComponent({
    name: "GroupPost",
    components: {
      CampHeader,
      CampFormHeader,
      CampFormRackSubmitBtn,
      ModalCropImage
    },
    setup() {
      const { showTimeAlert } = useAlert()
      const group = ref<IGroup>({
        name : '',
        logo : '',
        responsible : '',
        responsible_telephone : '',
        main_cnpj: '',
      });
      const loaderStore = useLoaderStore();
      const isRequiredField = ref(false)
      const imgCard = ref({
        imgBack:"" as any,
        imgNameLogo: "",
        imgLogo: "" as any,
        imgNameBack: ""
      });
      const proportion = ref()
      const modatlCroppedLogo = ref(true)

  
      // Functions
      async function onSubmitForm() {
        isRequiredField.value = false
        if(!group.value)
          return
        const requiredInputs = {
          name: group.value.name,
          responsible: group.value.responsible,
          responsible_telephone: group.value.responsible_telephone
        }
        if(checkEmptyFields(requiredInputs))
        return isRequiredField.value = true
      loaderStore.open()
      try {
            group.value.logo = imgCard.value.imgBack
            const result = await axios.post(`/api/createGroup`, {
              ...group.value,
              main_cnpj: typeof group.value.main_cnpj === "string" ? group.value.main_cnpj.replace(/[^a-zA-Z0-9]/g, '') : null
            })

            showTimeAlert("Registro criado com sucesso!")
            return router.push("/grupos")
        } catch (error) {
          if(error instanceof AxiosError)
            showTimeAlert(error.response?.data.message, "error")
          else
            showTimeAlert("Algo deu errado!", "error")
          loaderStore.close()
        }
      }

      function getImgLogo(event) {
          modatlCroppedLogo.value = true
          proportion.value = 10/10
          const fileInput = event.target as HTMLInputElement;
          const file = fileInput.files?.[0];
          if (file) {
            imgCard.value.imgLogo = file
            imgCard.value.imgNameLogo = file.name
          }
          fileInput.value = "";
      }

      const auxModal:any = ref(null);
      const openModal = id => {
          const auxElement = document.querySelector(`#${id}`);
          auxModal.value = new Modal(auxElement);
          auxModal.value.show();
        };
      const auxModalBack:any = ref(null);
      const openModalBack = id => {
          const auxElement = document.querySelector(`#${id}`);
          auxModalBack.value = new Modal(auxElement);
          auxModalBack.value.show();
        };

      function setImageCroped(value) {
      if(!modatlCroppedLogo.value) {
        imgCard.value.imgBack = value.croppedImgBase64
        openModalBack("staticBackdropBack")
      }
      if(modatlCroppedLogo.value) {
        imgCard.value.imgLogo = value.croppedImgBase64
        openModal("staticBackdrop")
      }
    }

    function getImgBack(event) {
      modatlCroppedLogo.value = false
      proportion.value = 10/6
      const fileInput = event.target as HTMLInputElement;
      const file = fileInput.files?.[0];
      if (file) {
        imgCard.value.imgBack = file
        imgCard.value.imgNameBack= file.name
      }
      fileInput.value = "";
    }

      function handleRemoveImgLogo() {
        imgCard.value.imgNameBack = ""
        imgCard.value.imgBack = null
        group.value.logo = ""
      }

      return {
        isRequiredField,
        onSubmitForm,
        group,
        getImgLogo,
        imgCard,
        proportion,
        modatlCroppedLogo,
        setImageCroped,
        getImgBack,
        openModalBack,
        auxModalBack,
        handleRemoveImgLogo,
      }
    }
  })
  